<template xmlns="http://www.w3.org/1999/html">

  <div style="background-color: #e9e9e9">
    <!-- 头部 -->
    <Header></Header>
    <!-- /头部 -->

    <!-- 体部 -->
    <!-- 轮播图 -->
    <Carousel></Carousel>
    <div class="card_list">
      <el-card class="box-card">
        <div v-for="item in cardList" :key="item" class="text item">
          <el-row type="flex" class="row-bg" justify="space-around">
            <el-col :span="8">
              <div class="grid-content_image">
                <img :src="item.img" style="width: 10vh">
              </div>
            </el-col>
            <el-col :span="20" v-if="!item.isMultiline">
              <div class="grid-content_p">
                <span >{{item.title}}</span>
              </div>
            </el-col>
            <el-col :span="20" v-else>
              <div class="grid-content_p_multiline">
                <p >{{item.title.split(",")[0]}}</p>
                <p >{{item.title.split(",")[1]}}</p>
              </div>
            </el-col>
            <el-col :span="14">
              <div >
                <el-button type="primary" size="small" @click="onBack(item.src)" class="button_on" >{{item.button}}</el-button>
              </div>
            </el-col>
          </el-row>

        </div>
      </el-card>
    </div>


  </div>


</template>
 
<script>
import { get, post } from "@/utils/request";
  export default {
    components: {},
    data() {
      return {
        cardList:[
          // {'img':require("@/assets/xyhd/du_xiaoman.jpg"),'isMultiline':false,'title':'度小满正规借款平台','button':'立即申请','src':'https://xindai.duxiaoman.com/cloan/operation/activity?activityName=channelBrand&CH=jmall&fr=KdICRRr40'},
          // {'img':require("@/assets/xyhd/360.jpg"),'isMultiline':false,'title':'最高可借200000','button':'查看额度','src':'https://cdn.xjietiao.com/at/xyhd09xz/xyhd-08xz-01'},
          {'img':require("@/assets/xyhd/huaxiaozhu.png"),'isMultiline':true,'title':'在花小猪平台的新司机,完单最高得600元现金','button':'成为司机','src':'https://v.didi.cn/dpMzRPk?source_id=zy01'},
          {'img':require("@/assets/xyhd/didi.png"),'isMultiline':true,'title':'用滴滴轻松出行,领取滴滴出行打车券','button':'领取礼包','src':'https://v.didi.cn/LMnE8oZ?source_id=zy02'},
          {'img':require("@/assets/xyhd/dida.jpg"),'isMultiline':true,'title':'成为滴答顺风车车主,顺路接单轻松赚油钱','button':'成为车主','src':'https://web.didachuxing.com/web-new-coupon/?activityCode=270df0119b8940eeb45d8eea913fae9c'}
        ],
    };
  },
  created() {},
  methods: {
    onBack(src){
      console.log(src);
      window.location.href=src;
    },
  },

};
</script>

<style lang='scss'>
  // 体部样式
  .text {
    font-size: 14vh;
  }

  .item {
    padding: 18px 0;
  }

  .row-bg {
    border-bottom: 1px dotted #c2c2c2;
    height: 10vh;
    width: 100%;
  }
  .card_list {
    width: 100%;
  }
  .box-card {
    width: 100%;
    text-align: center;
  }
  .grid-content_image{
    position: absolute;
    top: 47%;
    left: 8%;
    transform: translate(-47%, -58%);
  }
  .grid-content_p{
    font-size: 0.16em;
    position: absolute;
    top: 32%;
    left: 44%;
    transform: translate(-47%, -58%);
  }
  .grid-content_p_multiline{
    font-size: 0.16em;
    position: absolute;
    top: 11%;
    left: 44%;
    transform: translate(-47%, -58%);
     p {
      display: block;
      margin-block-start: 1em;
      margin-block-end: -1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;

    }
  }
  .button_on{
    position: absolute;
    top: 45%;
    left: 86%;
    transform: translate(-47%, -86%);

    font-size: 0.12em;


  }


</style>